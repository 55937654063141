import React, {useEffect} from "react";
import "./LoginPage.css";
import {Link} from "react-router-dom";
import authUser from "../Functions/Authentication/login";
import NavBar from "../NavBar";

function LoginPage(props) {
  const { username, password, rememberMe, login2, notAMemberSignUpNow } = props;

  useEffect(() => {
    if (window.sessionStorage.loggedIn) {
      window.location.href = "/profile";
    }
  }, []);

  return (
    <div className="login-content-container">
      <NavBar instanceName="loginPageNavBar"/>
      <div className="loginpage screen">
        <div className="overlap-group6">
        </div>
        
        <div className="overlap-group4">
          <div className="login">Login</div>
          <div className="overlap-group1-1">
            <input type="text" id="usernameField" className="username" placeholder={username}></input>
          </div>
          <div className="overlap-group1-1">
            <input type="password" id="passwordField" className="password" placeholder={password}></input>
          </div>
          <div className="remember-me">
            <div className="rectangle-13"></div>
            <div className="remember-me-1">{rememberMe}</div>
          </div>
          <div className="overlap-group-2">
            <div onClick={loginClicked} className="login-1">{login2}</div>
          </div>
            <Link to="/createaccount">
              <p className="not-a-member-sign-up-now">{notAMemberSignUpNow}</p>
            </Link>
        </div>
        <div className="spacer"/>
      </div>
    </div>
  );
}

function loginClicked() {
  authUser(document.getElementById("usernameField").value, document.getElementById("passwordField").value);
}

export default LoginPage;
