import React from "react";



function requestUrl(path) {
    // Production URL
    let url = "https://api.score-score.com";

    // Testing URL
    //let url = "http://localhost:8080"

    return url + path;
}

export default requestUrl
