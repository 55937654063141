import React, {useEffect, useRef, useState} from "react";
import request from "../Functions/Request/request";
import contents from "../Functions/Request/contents";
import methods from "../Functions/Request/methods";
import "./ProfilePage.css";
import logout from "../Functions/Authentication/logout";
import NavBar from "../NavBar";

function ProfilePage(props) {
    const {
        profile,
        spanText1,
        spanText3,
        recentActivities,
        spanText7,
        spanText8,
        spanText9,
        spanText10,
    } = props;

    const usernameRef = useRef(undefined);
    const fullNameRef = useRef(undefined);
    const firstNameRef = useRef(undefined);
    const lastNameRef = useRef(undefined);
    const emailRef = useRef(undefined);
    const editButtonRef = useRef(undefined);
    const positionDisplayRef = useRef(undefined);
    const positionSelectRef = useRef(undefined);
    const selectorRef = useRef(undefined);

    const [user, setUser] = useState(undefined);
    const [editing, setEditing] = useState(false);
    const [first, setFirst] = useState(true);
    const [positions, setPositions] = useState(undefined);

    useEffect(() => {
        hideInputs();
        request("/api/positions", methods.get, contents.json, null,
            (data) => {
                setPositions(data);
            },
            () => {
                alert("Failed to make request for teaching positions!")
            })
        request("/api/contributor", methods.get, contents.json, null,
            (data) => {
                setUser(data);
            },
            () => {
                alert("Failed to make API request for contributor information.");
            },
            () => {
                console.log("Not logged in. Redirecting to login page.");
                window.sessionStorage.removeItem("loggedIn");
                window.location.href = "/loginpage";
            },
            () => {
                console.log("Not logged in. Redirecting to login page.");
                window.sessionStorage.removeItem("loggedIn");
                window.location.href = "/loginpage";
            });
    }, []);

    useEffect(() => {
        if (editing) {
            showInputs();
        }
        else {
            if (!first) {
                request("/api/contributor", methods.put, contents.json, JSON.stringify(user),
                    (data) => {
                        setUser(data);
                        alert("Updated profile");
                    },
                    () => {
                        alert("Failed to make API request.");
                    });
            }
            else {
                setFirst(false);
            }
            hideInputs();
        }
    }, [editing])

    const hideInputs = () => {
        firstNameRef.current.style.display = "none";
        lastNameRef.current.style.display = "none";
        positionSelectRef.current.style.display = "none";
        fullNameRef.current.style.display = "block";
        positionDisplayRef.current.style.display = "block";
        usernameRef.current.readOnly = true;
        emailRef.current.readOnly = true;
        editButtonRef.current.innerText = "Edit Profile";
    }

    const showInputs = () => {
        firstNameRef.current.style.display = "block";
        lastNameRef.current.style.display = "block";
        positionSelectRef.current.style.display = "block";
        fullNameRef.current.style.display = "none";
        positionDisplayRef.current.style.display = "none";
        usernameRef.current.readOnly = false;
        emailRef.current.readOnly = false;
        editButtonRef.current.innerText = "Save";
    }

    const editClicked = (event) => {
        let tempPositions = [];
        let options = selectorRef.current.options;
        for (let i = 0; i < options.length; i++) {
            let op = options[i];
            if (op.selected) {
                tempPositions.push(({positionId:op.value, positionName:op.innerText}));
            }
        }
        let tempUser = structuredClone(user);
        tempUser.teachingPositions = tempPositions;

        setUser(tempUser);
        setEditing(!editing);
    }

    const optionClicked = (event) => {
        event.preventDefault();
        event.target.selected = !event.target.selected;
    }

    return (
        <div className="profilepage-container-center-horizontal">
            <NavBar instanceName="UserProfilePageNavBar"/>
            <div className="profilepage-profilepagescreen">
                <div className="profilepage-overlap-group-container">
                    <div className="profilepage-flex-col">
                        <div className="profilepage-flex-col-1">
                            <div className="profilepage-flex-row profile-20px-bold-text">{profile}</div>
                        </div>
                        <hr/>
                        <div className="profilepage-flex-row-1">
                            <img className="profilepage-image-22" src="/img/defaultProfileIcon.svg" alt="image 22" />
                            <div className="profilepage-flex-col-2">
                                <input className="profilepage-name profile-32px-normal-text" ref={usernameRef} type="text" value={user ? user.name : ""} onChange={(event) => {
                                    let tempUser = structuredClone(user);
                                    tempUser.name = event.target.value;
                                    setUser(tempUser);
                                }}/>
                                <input className="profilepage-name profile-20px-normal-text" ref={firstNameRef} type="text" value={user ? user.firstName : ""} onChange={(event) => {
                                    let tempUser = structuredClone(user);
                                    tempUser.firstName = event.target.value;
                                    setUser(tempUser);
                                }}/>
                                <input className="profilepage-name profile-20px-normal-text" ref={lastNameRef} type="text"  value={user ? user.lastName : ""} onChange={(event) => {
                                    let tempUser = structuredClone(user);
                                    tempUser.lastName = event.target.value;
                                    setUser(tempUser);
                                }}/>
                                <p ref={fullNameRef} className="profilepage-name profile-20px-normal-text">
                                    {user ? user.firstName + " " + user.lastName : ""}
                                </p>
                                <input className="profilepage-name profile-20px-normal-text" ref={emailRef} type="text" value={user ? user.email : ""} onChange={(event) => {
                                    let tempUser = structuredClone(user);
                                    tempUser.email = event.target.value;
                                    setUser(tempUser);
                                }}/>
                                <p className="profilepage-name profile-20px-normal-text">
                                    Teaching Positions:
                                </p>
                                <div ref={positionSelectRef} className="accountCreation-roleSelector-overlap">
                                    <select ref={selectorRef} className="roleSelector profile-20px-normal-text" multiple>
                                        {user && positions ?
                                            positions.map(pos => <option onMouseDown={optionClicked} value={pos.positionId} selected={
                                                user.teachingPositions.some(item => item.positionName === pos.positionName)
                                            }>{pos.positionName}</option>)
                                            : undefined
                                        }
                                    </select>
                                </div>
                                <p ref={positionDisplayRef} className="profilepage-teaching-positions profile-20px-normal-text">
                                    {user ? user.teachingPositions.map(item => item.positionName).join(", ") : ""}
                                </p>
                                <div ref={editButtonRef} className="profilepage-edit-button profile-20px-normal-text" onClick={editClicked}>Edit Profile</div>
                            </div>
                        </div>
                        <hr/>
                        <div className="profilepage-flex-col-3">
                            <p className="profilepage-your-subscription-st profile-32px-normal-text">
                                <span className="profile-20px-bold-text">{spanText1}</span>
                                <span className="profile-20px-normal-text">{spanText3}</span>
                            </p>
                            <hr/>
                            <div className="profilepage-recent-activities profile-36px-normal-text">
                                {recentActivities}
                            </div>
                            <div className="profilepage-flex-row-2">
                                <img className="profilepage-image-23" src="/img/defaultProfileIcon.svg" alt="image 23" />
                                <div className="profilepage-overlap-group3">
                                    <p className="profilepage-you-reviewed-fugue-i profile-20px-bold-text">
                                        <span className="profile-20px-bold-text">{spanText7}</span>
                                        <span className="profile-20px-normal-text">{spanText8}</span>
                                        <span className="profile-20px-bold-text">{spanText9}</span>
                                        <span className="profile-20px-normal-text">{spanText10}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="profilepage-logout-holder">
                            <div className="profilepage-logout-button profile-20px-normal-text" onClick={() => {
                                logout();
                                window.location.href = "/";
                            }
                            }>Logout</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ProfilePage;
