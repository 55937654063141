import React, {useState} from "react";
import "./SearchPage.css";
import ReactDOM from "react-dom";
import requestUrl from "../Functions/variables/requestUrl.jsx";
import request from "../Functions/Request/request.jsx";
import getRequest from "../Functions/Request/methods.jsx";
import methods from "../Functions/Request/methods.jsx";
import contents from "../Functions/Request/contents.jsx";
import {Link} from "react-router-dom";
import NavBar from "../NavBar";


function SearchPage(props) {
  const {
    searchForPiece
  } = props;
  let requestFailed = false;
  
  request("/api/pieces", methods.get, contents.json, null, function(data) {
    renderList(data);
    renderUniqueGrades(data)
    document.addEventListener('scroll', document.scrollEvent)
  },
  function() {
      alert("Request failed!");
      requestFailed = true;
  },
  function() {
      alert("You are not logged in");
      requestFailed = true;
  },
  function() {
      alert("You do not have permission to access this!");
      requestFailed = true
  });

  return (
    <div className="search">
      <div className="search-results">
      <NavBar instanceName="searchNav"/>
        <div className="search-area">
          <div className="search-page-top-text">
            <div className="text-wrapper">{searchForPiece}</div>
          </div>
          <div className="search-categories">
            <div className="const-search">
              <div className="div-2">
                <div className="name">Title</div>
                <input type="text" className="search-box" id="FilterTitle" onInput={updateListForFilter}></input>
              </div>
              <div className="div-2">
                <div className="name">Composer</div>
                <input type="text" className="search-box" id="FilterComposer" onInput={updateListForFilter}></input>
              </div>
              <div className="div-2">
                <div className="name">Arranger</div>
                <input type="text" className="search-box" id="FilterArranger" onInput={updateListForFilter}></input>
              </div>
              <div className="grade-level" id="searchGradeLevel">
                <div className="name-2">Grade Level</div>
                <div className="check-boxes" id="searchGradeHolder">

                </div>
              </div>
            </div>
            {SearchExpandCaret(props)}
          </div>
        </div>
        <div className="search-results-2">
          <div className="sort-box">
            <div className="text-4">Sort by: Most Recent</div>
            <img
              className="drop-down-3"
              alt="Drop down"
              src="/img/dropDownDown.svg"
            />
          </div>
          <div className="add-button">
            <p className="p">Missing a piece? Add it here!</p>
          </div>
          <div className="results" id="results">
          </div>
          
        </div>
      </div>
    </div>
  );
}

// An example of how to send a post request to the API
function sendNewPiece() {
  const newRequest = {
    method: "POST",
    headers: {'Content-Type': 'application/json;charset=utf-8'},
    body: JSON.stringify({title:'test 5', composer:'composer 5', arranger:'arranger 5', publisher:'publisher 5', grade:4.0, pieceId:-1})

  };

  fetch("https://api.score-score.com/api/piece", newRequest).then((response) => response.json())
      .then((data) => console.log(data));
}

function searchPieces(pieceTitle, pieceArranger, pieceComposer, grades) {
  let requestFailed = false;

  request("/api/search", methods.post, contents.json,
      JSON.stringify({pieceTitle:pieceTitle, arrangerName:pieceArranger, composerName:pieceComposer, grades:grades}),
      function (data) {
        if (!requestFailed) {
            renderList(data);
        }
      },
      function() {
        alert("Failed!")
        requestFailed = true;
      },
      function() {
          alert("Not logged in!")
          requestFailed = true;
      },
      function() {
          alert("Not authenticated!")
          requestFailed = true;
      });
}

let pageData = null;
let records = 20;
let moreEntries = true

function hasMore() {
    return moreEntries;
}

function setData(data) {
    pageData = data;

    if (data.length < 20) {
        records = data.length;
    }
    else if (records < 20) {
        records = 20;
    }
}

function updateListForFilter() {
  
  let checks = Array.from(document.getElementById("searchGradeHolder").children).map(element =>
    element.children[1]
  )

  let grades = []
  checks.map(item => {
      if (item['checked']) {
          grades.push(item.value);
      }
  })

  searchPieces(document.getElementById("FilterTitle").value,
      document.getElementById("FilterArranger").value,
      document.getElementById("FilterComposer").value, grades.join(","));
}

document.scrollEvent = function () {

    if (window.location.pathname === "/searchpage") {
        if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 10) {
            loadMore()
        }
    }
}

function loadMore() {
    if (pageData != null) {
        if (records < pageData.length) {
            console.log(records)
            records += 20;
        }
        else {
            records = pageData.length;
        }
        renderList(pageData)
    }

}

function renderList(data) {
    setData(data)
    let useData = data.slice(0, records)
    let entries = useData.map(item =>
        <div className="row" onClick={() => {
            window.sessionStorage["pieceId"] = item['pieceId'];
            window.location.href = "/piecepage";
        }}>
            <div className="result">
              <div className="title">
                <div className="title-2">{item['title']}</div>
              </div>
              <div className="other-info">
                <div className="more-info">
                  <div className="div-4">
                    <div className="composer">{item['composer']}</div>
                  </div>
                  <div className="div-4">
                    <div className="arranger">
                      <div className="text-wrapper-2">Arranger: {item['arranger']}</div>
                    </div>
                    <div className="grade">
                      <div className="text-wrapper-2">Grade: {item['grade']}</div>
                    </div>
                  </div>
                </div>
                <div className="div-2">
                  <img
                    className="stars" 
                    alt="Stars"
                    src="/img/tempstars.png"
                  />
                  <div className="rating-nums">4.5&nbsp;&nbsp;(150)</div>
                </div>
              </div>
            </div>
        </div>);

    ReactDOM.render(entries, document.getElementById("results"));
}

function renderUniqueGrades(pieces) {

    let grades = []

    pieces.map(item => {
        if (grades.indexOf(item['grade']) == -1) {
            grades.push(item['grade']);
        }
    })

    grades.sort()

    let gradeBoxes = grades.map(item =>
        <div style={{display: 'flex'}}>
            <div style={{width: '50%', fontFamily: "verdana, sans-serif"}}>{item}</div>
            <input type="checkbox" value={item} onInput={updateListForFilter}></input>
        </div>
    );

    ReactDOM.render(gradeBoxes, document.getElementById("searchGradeHolder"));
}

function componentDidMount() {
    document.addEventListener('scroll', scrollEvent)
}

function componentWillUnmount() {
    document.removeEventListener('scroll', scrollEvent)
}


function SearchExpandCaret(props) {
  const [isActivated, setActivate] = useState(false);

  const toggleStyle = () => {
    setActivate(!isActivated);
  };

  if (!isActivated) {
    return (
        <div className="expand-box">
          <div className="text-2" onClick={toggleStyle}>Additional Search</div>
            <img
              className="drop-down"
              alt="Drop down"
              src="/img/dropDownDown.svg"
              onClick={toggleStyle}
            />
          </div>
    );
  }

  return (
          <div>
            <div className="expand-box">
              <div className="text-2" onClick={toggleStyle}>Additional Search</div>
                <img
                  className="drop-down"
                  alt="Drop down"
                  src="/img/dropDownUp.svg"
                  onClick={toggleStyle}
                />
              </div>
              <div className="ex-search">
                <div className="div-2">
                  <div className="name">Rating</div>
                  <select onChange={updateListForFilter}  id="rating" className="drop-box">
                    <option value="noFilter">Any Rating</option>
                    <option value="1rating">1 Star</option>
                    <option value="2rating">2 Stars</option>
                    <option value="3rating">3 Stars</option>
                    <option value="4rating">4 Stars</option>
                    <option value="5rating">5 Stars</option>
                  </select>
                </div>
                <div className="div-2">
                  <div className="name">State List*</div>
                  <select className="drop-box" id="FilterStateList" onChange={updateListForFilter}>
                    <option value="noList">All State Lists</option>
                  </select>
                </div>
                <div className="div-2">
                  <div className="name">Composer Demographics</div>
                  <select className="drop-box" id="FilterDemographic" onChange={updateListForFilter}>
                    <option value="noDemographic">All Composer Demographics</option>
                  </select>
                </div>
                <div className="div-2">
                  <div className="name">Cultural Influence</div>
                  <select className="drop-box" id="FilterInfluence" onChange={updateListForFilter}>
                    <option value="noInfluence">All Cultural Influences</option>
                  </select>
                </div>
                <div className="div-2">
                  <div className="name">Type</div>
                  <select className="drop-box" id="FilterType" onChange={updateListForFilter}>
                    <option value="noType">Any Type</option>
                  </select>
                </div>
                <div className="div-2">
                  <div className="name">Other</div>
                  <select className="drop-box" id="FilterOther" onChange={updateListForFilter}>
                    <option value="noOther">Any</option>
                  </select>
                </div>
                <div className="div-2">
                  <div className="name-2">Instrumentation</div>
                  <div className="check-boxes-2">
                    <div className="div-3">
                      <img
                        className="box"
                        alt="Box"
                        src="/img/box.png"
                      />
                      <div className="text">Flex</div>
                    </div>
                    <div className="div-3">
                      <img
                        className="box"
                        alt="Box"
                        src="/img/box.png"
                      />
                      <div className="text">Solo</div>
                    </div>
                    <div className="div-3">
                      <img
                        className="box"
                        alt="Box"
                        src="/img/box.png"
                      />
                      <div className="text">World Music Parts</div>
                    </div>
                    <div className="div-3">
                      <img
                        className="box"
                        alt="Box"
                        src="/img/box.png"
                      />
                      <div className="text">Other</div>
                    </div>
                  </div>
                  <div className="section-feature">
                    <div className="text-3">Section Feature</div>
                    <select className="drop-box" id="FilterSectionFeature" onChange={updateListForFilter}>
                      <option value="noFeature">Any Section Feature</option>
                    </select>
                  </div>
                </div>
                <p className="disclaimer">
                <span>*</span>
                <span className="disclaimer-italics">Score-Score</span>
                <span> can not guarantee state band list accuracy. Be sure to check official listings, and if you are aware of state list updates, please let us know.</span>
              </p>
              </div>
            </div>
  );
}

export default SearchPage;
