import React, {useEffect, useRef, useState} from "react";
import "./PiecePage.css";
import NavBar from "../NavBar";
import request from "../Functions/Request/request";
import contents from "../Functions/Request/contents";
import methods from "../Functions/Request/methods";

function PieceView(props) {
    const {
        dropDownDown
    } = props;

    const reviewAreaRef = useRef(undefined);
    const commentAreaRef = useRef(undefined);
    const ratingRef = useRef(undefined);

    const [piece, setPiece] = useState(undefined);
    const [reviews, setReviews] = useState(undefined);
    const [update, setUpdate] = useState(true);

    const pieceId = window.sessionStorage["pieceId"];
    useEffect(() => {
        request("/api/piece?piece_id=" + pieceId, methods.get, contents.json, null, (data) => {
            setPiece(data);
        });

        request("/api/review?piece_id=" + pieceId, methods.get, contents.json, null,
            (data) => {
                const newReviews = data.map((review) => <Review review={review} canReply={true} update={update} setUpdate={setUpdate} />);
                setReviews(newReviews);
            });
    }, [update]);

    const postReview = (e) => {
      request("/api/review/post?piece_id=" + pieceId, methods.post, contents.json, JSON.stringify({ensemble:"", rating:ratingRef.current.value, content:commentAreaRef.current.value}),
          (data) => {
            reviewAreaRef.current.className = "piecepage-review-add-area-hidden";
            setUpdate(!update);
            reviewAreaRef.current.value = "";
            ratingRef.current.value = 5;
          },
          () => {
            alert("failed to post review");
          },
          () => {
              reviewAreaRef.current.className = "piecepage-review-add-area-hidden";
              alert("You are not logged in. Please login to leave a review.")
          });
    };

    return (
    <div className="piecePage">
      <div className="the-content">
      <NavBar instanceName="piecePageNavBar"/>
        <div className="info">
          <div className="main-data">
            <div className="top-info">
              <div className="main-info">
                <div className="pieceTitle">{piece ? piece.title : "Loading"}</div>
                <div className="pieceComposer">{piece ? piece.composer : ""}</div>
              </div>
              <div className="have-want-play-buttons">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    {ToggleButtonClass(props, "I have played this")}
                  </div>
                </div>
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                  {ToggleButtonClass(props, "I want to play this")}
                  </div>
                </div>
              </div>
            </div>
            {pieceExpandCaret(props, piece, pieceId)}
          </div>
        </div>
        <div className="refs-and-ratings">
          <div className="references">
            <div className="ref-header-text">
              <div className="ref-header-text-2">References</div>
            </div>
            <div className="the-links">
              <p className="links">
                Wind Repertory Project Link
                <br />
                Director’s Notes Link
                <br />
                Audio Reference #1
                <br />
                Audio Reference #2
              </p>
            </div>
            <div className="reference-button">
              <div className="reference-button-text"onClick={() => {
                  window.sessionStorage["pieceId"] = pieceId;
                  window.location.href = "/editpiece";
                  }}>Add another?</div>
              </div>
          </div>
          <div className="avg-rating">
            {AvgStarsFormat(reviews ? GetAverage(reviews) : 0)}
            <div className="numbers">
              <div className="text-wrapper-2">
                {reviews ? GetAverage(reviews) : 0}
              </div>
              <div className="text-wrapper-2">{reviews ? "(" + reviews.length +  ")" : "(0)"}</div>
            </div>
          </div>
        </div>
        <div className="reviews">
        <header className="header">
              <div className="header-2">{reviews ? "Reviews (" + reviews.length + ")" : "Reviews"}</div>
            </header>
            <div className="search-box">
            {/* onClick={sortRatingDesc(reviews ? reviews : "")} */}
              <div className="text-6">Sort by: Most Recent</div>
              <img
                className="img"
                alt="Drop down"
                src={dropDownDown}
              />
            </div>
          <a className="rev-button" onClick={ (e) => {
            if (window.sessionStorage["loggedIn"]) {
                reviewAreaRef.current.className = "piecepage-review-add-area";
            }
            else {
                window.location.href = "/loginpage";
            }
            }}>
            <div className="text-7">Write a review</div>
          </a>
          <div ref={reviewAreaRef} className="piecepage-review-add-area-hidden">
              <p className="enterRevText">Rating (1 through 5, 5 is best)</p>
              
              <input className="enterRevTextDark enterRevTextDark" ref={ratingRef} type="number" min="0" max="5" onChange={(e) => {
                  let rating = parseInt(ratingRef.current.value);
                  ratingRef.current.value = Math.floor(rating);
                  if (rating < 0) {
                      ratingRef.current.value = 0;
                  }
                  else if (rating > 5) {
                      ratingRef.current.value = 5;
                  }
              }} onWheel={(e) => {e.currentTarget.blur()}}/>
              <br/>
              <p className="enterRevText">Comments</p>
              <textarea ref={commentAreaRef} className="enterCommentArea enterRevTextDark" onChange={(e) => {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
              <br/>
              <p className="enterRevTextHover" onClick={postReview}>Post</p>
              <br/>
              <p className="enterRevTextHover" onClick={ (e) => {
                  reviewAreaRef.current.className = "piecepage-review-add-area-hidden";
              }}>Cancel</p>
          </div>
          <div className="rev-button-2">
          <div className="piecepage-review-container">
            {reviews ? reviews : ""}
          </div>
          </div>
        </div>
      </div>
      <div className="piece-spacer"/>
    </div>
    );
}


function AvgStarsFormat(rating) {
  var emptyStars = 4 - rating;
  var fracStar;
  var partStarDiv;

  // 5 or 0 rating doesn't need any partial note calculations
  if (rating >= 5){
    return FillStars5();
  }

  if (rating < 0.1) {
    return EmptyStars5();
  }

  // add the fully filled stars to the layout
  // 4.x star
  if ( rating >= 4 ) {
    partStarDiv = FillStars4();
  }

  // 3.x star
  else if ( rating >= 3 ) {
    partStarDiv = FillStars3();
  }

  // 2.x star
  else if ( rating >= 2 ) {
    partStarDiv = FillStars2();
  }

  // 1.x star
  else if ( rating >= 1 ) {
    partStarDiv = FillStars1();
  }

  // get the partially filled star
  fracStar = rating;

  while ( fracStar >= 1 ) {
    fracStar -= 1;
  }

  partStarDiv = (
    <span>
      {partStarDiv}
      {PartialStar(fracStar)}
    </span>
  );

  // add the empty stars
  if ( emptyStars > 3 ){
    partStarDiv = (
      <span>
        {partStarDiv}
        {EmptyStars4()}
      </span>
    );
  }

  else if ( emptyStars > 2 ){
    partStarDiv = (
      <span>
        {partStarDiv}
        {EmptyStars3()}
      </span>
    );
  }

  else if ( emptyStars > 1 ){
    partStarDiv = (
      <span>
        {partStarDiv}
        {EmptyStars2()}
      </span>
    );
  }

  else if ( emptyStars > 0 ){
    partStarDiv = (
      <span>
        {partStarDiv}
        {EmptyStars1()}
      </span>
    );
  }

  return partStarDiv;
}



function EmptyStars1() {
  const starEmpty = "/img/0note.svg"

  return( 
    <span>
      <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
    </span>
  );
}



function EmptyStars2() {
  const starEmpty = "/img/0note.svg"

  return( 
    <span>
      <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
        <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
    </span>
  );
}



function EmptyStars3() {
  const starEmpty = "/img/0note.svg"

  return( 
    <span>
      <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
        <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
        <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
    </span>
  );
}



function EmptyStars4() {
  const starEmpty = "/img/0note.svg"

  return( 
    <span>
      <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
        <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
        <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
        <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
    </span>
  );
}



function EmptyStars5() {
  const starEmpty = "/img/0note.svg"

  return( 
    <span>
      <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
        <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
        <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
        <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
        <img
          className="stars"
          alt="emptyStar"
          src={starEmpty}
        />
    </span>
  );
}



function FillStars1() {
  const starFilled = "/img/10note.svg"

  return (
    <span>
        <img
            className="stars"
            alt="filledStar"
            src={starFilled}
        />
    </span>
  );
}



function FillStars2() {
  const starFilled = "/img/10note.svg";

  return (
    <span>
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
      </span>
  );
}



function FillStars3() {
  const starFilled = "/img/10note.svg";

  return (
    <span>
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
      </span>
  );
}



function FillStars4() {
  const starFilled = "/img/10note.svg";

  return (
    <span>
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
      </span>
  );
}



function FillStars5() {
  const starFilled = "/img/10note.svg";

  return (
    <div>
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
          <img
            className="stars"
            alt="filledStar"
            src={starFilled}
          />
      </div>
  );
}



function GetAverage(data) {
  var avg = 0.0;
  var length = data.length;
  
  // run through all the reviews, getting the rating
  data.forEach(element => {
    avg = avg + element.props.review.rating;
  });

  // don't divide by 0
  if ( length == 0) {
    return 0;
  }

  // get the average, round to nearest .1
  avg = (avg / length).toFixed(1);
  
  return avg;
}



function PartialStar(rating) {
  const star1 = "/img/1note.svg";
  const star2 = "/img/2note.svg";
  const star3 = "/img/3note.svg";
  const star4 = "/img/4note.svg";
  const star5 = "/img/5note.svg";
  const star6 = "/img/6note.svg";
  const star7 = "/img/7note.svg";
  const star8 = "/img/8note.svg";
  const star9 = "/img/9note.svg";
  const star10 = "/img/10note.svg";
  
  // less than x.1, treat as x.0
  if (rating < 0.1 ) {
    return EmptyStars1();
  }

  // .1
  else if ( rating < 0.2) {
    return (
      <span>
        <img
            className="stars"
            alt="partialStar"
            src={star1}
          />
      </span>
    );
  }

  // .2
  else if ( rating < 0.3) {
    return (
      <span>
        <img
            className="stars"
            alt="partialStar"
            src={star2}
          />
      </span>
    );
  }

  // .3
  else if ( rating < 0.4) {
    return (
      <span>
        <img
            className="stars"
            alt="partialStar"
            src={star3}
          />
      </span>
    );
  }

  // .4
  else if ( rating < 0.5) {
    return (
      <span>
        <img
            className="stars"
            alt="partialStar"
            src={star4}
          />
      </span>
    );
  }

  // .5
  else if ( rating < 0.6) {
    return (
      <span>
        <img
            className="stars"
            alt="partialStar"
            src={star5}
          />
      </span>
    );
  }

  // .6
  else if ( rating < 0.7) {
    return (
      <span>
        <img
            className="stars"
            alt="partialStar"
            src={star6}
          />
      </span>
    );
  }

  // .7
  else if ( rating < 0.8) {
    return (
      <span>
        <img
            className="stars"
            alt="partialStar"
            src={star7}
          />
      </span>
    );
  }

  // .8
  else if ( rating < 0.9) {
    return (
      <span>
        <img
            className="stars"
            alt="partialStar"
            src={star8}
          />
      </span>
    );
  }

  // .9
  else if ( rating < 1.0) {
    return (
      <span>
        <img
            className="stars"
            alt="partialStar"
            src={star9}
          />
      </span>
    );
  }

  // case for fully filled. shouldn't happen but we're checking just in case
  return (
    <span>
      <img
          className="stars"
          alt="partialStar"
          src={star10}
        />
    </span>
  );

}



function RevStarsFormat(rating) {
  
  var emptyStars = 5 - rating;

  // 5 empty stars
  if (emptyStars >= 5){
    return EmptyStars5();
  }
  
  // 4 empty, 1 filled
  else if (emptyStars >= 4) {
    return (
      <div>
          {FillStars1()}
          
          {EmptyStars4()}   
      </div>
    );
  }

  // 3 empty, 2 filled
  else if (emptyStars >= 3) {
    return (
      <div>
          {FillStars2()}
          {EmptyStars3()}
      </div>
    );
  }

  // 2 empty, 3 filled
  else if (emptyStars >= 2) {
    return (
      <div>
          {FillStars3()}
          {EmptyStars2()}
      </div>
    );
  }

  // 1 empty, 4 filled
  else if (emptyStars >= 1) {
    return (
      <div>
          {FillStars4()}
          {EmptyStars1()}
      </div>
    );
  }

  // 5 filled
  return FillStars5();
}



function Review(props) {
    const reviewAreaRef = useRef(undefined);
    const commentAreaRef = useRef(undefined);
    const reviewRef = useRef(undefined);

    const [user, setUser] = useState(undefined);
    const update = props.update;
    const setUpdate = props.setUpdate;
    const [replies, setReplies] = useState(undefined);
    const [currentUser, setCurrentUser] = useState(undefined);

    useEffect(() => {
        if (props.review.replies) {
            const temp = props.review.replies.map((reply) =>
                <Review review={reply} update={update} setUpdate={setUpdate}/>
            );
            setReplies(temp);
        }

        request("/api/contributor?contributor_id=" + props.review.contributorId, methods.get, contents.json, null,
            (data) => {
                setUser(data);
            });

        request("/api/contributor", methods.get, contents.json, null,
            (data) => {
                setCurrentUser(data);
            });
    }, [update]);

    const postReview = (e) => {
        request("/api/review/reply?review_id=" + props.review.reviewId, methods.post, contents.json, JSON.stringify({ensemble:"", rating:0, content:commentAreaRef.current.value}),
            (data) => {
                reviewAreaRef.current.className = "piecepage-review-textarea-container-hidden";
                setUpdate(!update);
                reviewAreaRef.current.value = "";
                ratingRef.current.value = 5;
            },
            () => {
                alert("failed to post review");
            },
            () => {
                reviewAreaRef.current.className = "piecepage-review-textarea-container-hidden";
                alert("You are not logged in. Please login to leave a review.")
            });
    };

    const deleteReview = (e) => {
        if (confirm("This will delete the review and all replies. Are you sure you want to continue?")) {
            request("/api/review?review_id=" + props.review.reviewId, methods.delete, contents.json, null,
                (data) => {
                    reviewRef.current.className = "piecepage-review-hidden";
                    setUpdate(!update);
                });
        }
    }

    return (
      <>
        <div ref={reviewRef} className="review">
          <div className="data-rating">
            <img
              className="profile-pic"
              alt="Profile picture"
              src="/img/defaultProfileIcon.svg"
            />

            <div className="reviewUserData">
              <div className="name">
                <div className="text-wrapper-3">{user ? user.name : undefined}</div>
              </div>

              <div className="div-wrapper">
                <div className="text-wrapper-3">{props.review.ensemble}</div>
              </div>

              <div className="div-wrapper">
                <div className="text-wrapper-3">{props.review.date}</div>
              </div>

              {props.canReply ?
                    <div className="rating"> 
                      {RevStarsFormat(props.review.rating)}
                        <p className="number">
                        {props.review.rating}
                        </p>
                    </div>
                      : undefined
              }
            </div>
              </div>
              <p className="review-text">
                {props.review.content}
                </p>
          </div>

          <div ref={reviewAreaRef} className="piecepage-review-add-area-hidden">
            <p className="enterRevText">Comments</p>
            <textarea ref={commentAreaRef} className="enterCommentArea enterRevTextDark" onChange={(e) => {
                e.target.style.height = "0px";
                e.target.style.height = (e.target.scrollHeight + 5) + "px";
            }}/>
            <br/>
            <p className="enterRevTextHover" onClick={postReview}>Post</p>
            <br/>
            <p className="enterRevTextHover" onClick={ (e) => {
                reviewAreaRef.current.className = "piecepage-review-add-area-hidden";
            }}>Cancel</p>
          </div>
          
          {currentUser && user && currentUser.contributorId === user.contributorId ?
              <p className="reply-button" onClick={deleteReview}>Delete</p>
              : undefined
          }

          { props.canReply ?
              <a className="reply-button" onClick={ (e) => {
                  if (window.sessionStorage["loggedIn"]) {
                  reviewAreaRef.current.className = "piecepage-review-add-area";
                  }
                      else {
                      window.location.href = "/loginpage";
                  }
                  }}>
                  Reply
              </a>
          : undefined
          }

          <div className="piecepage-review-reply-container">
              {replies ? replies : ""}
          </div>
      </>
  );
}



function sortDateAsc(data) {
  var length = data.length;
  
  var i, j;

  for ( i = 1; i < length; i++ ) {
    for ( j = i; j > 0; j = j-- ) {
        if ( data[j].date < data[j - 1].date) {
          Swap(data, j, j - 1);
        }
    }
  }
  
  return data;
}



function sortDateDesc(data) {
  var length = data.length;
  
  var i, j;

  for ( i = 1; i < length; i++ ) {
    for ( j = i; j > 0; j = j-- ) {
        if ( data[j].date > data[j - 1].date) {
          Swap(data, j, j - 1);
        }
    }
  }
  
  return data;
}



function sortRatingAsc(data) {
  var length = data.length;
  
  var i, j;

  for ( i = 1; i < length; i++ ) {
    for ( j = i; j > 0; j = j-- ) {
        if ( data[j].rating < data[j - 1].rating) {
          Swap(data, j, j - 1);
        }
    }
  }
  
  return data;
}



function sortRatingDesc(data) {
  var length = data.length;
  
  var i, j;

  for ( i = 1; i < length; i++ ) {
    for ( j = i; j > 0; j = j-- ) {
        if ( data[j].rating < data[j - 1].rating) {
          Swap(data, j, j - 1);
        }
    }
  }
  
  return data;
}



function StateList(props) {
  return (
      <div className="piecepage-list-item">
          <div className="listArea">
              <p className="piecepage-state-name">
                  {props.stateName}
              </p>
          </div>
      </div>
  );
}



function Swap(data, index1, index2) {
  let temp = data[index1];

  data[index1] = data[index2];
  data[index2] = temp;
}



function ToggleButtonClass(props, text) {
  const [isActivated, setActivate] = useState(false);

  const toggleStyle = () => {
    setActivate(!isActivated);
  };

  return (
    <div className={isActivated ? "play-button-dark" : "play-button-light" } onClick={toggleStyle}>
        <div className={isActivated ? "play-text-light" : "text"}>{text}</div>
    </div>
  );
}


function pieceExpandCaret(props, piece, pieceId) {
  const [isActivated, setActivate] = useState(false);

  const toggleStyle = () => {
    setActivate(!isActivated);
  };

  if(isActivated) {
    return (
      <div>
      <div>
          <div className="expand-box">
              <div className="more-details" onClick={toggleStyle}>More Details</div>
              <img
                className="details-drop-down"
                alt="Drop down"
                src="/img/dropDownUp.svg"
                onClick={toggleStyle}
              />
            </div>
          </div>
          <div className="extra-info">
            <div className="piece-text-info">
              <div className="text-2">Arranger</div>
              <div className="value">{piece ? piece.arranger : ""}</div>
            </div>
            <div className="piece-text-info">
              <div className="text-2">Publisher</div>
              <div className="value">{piece ? piece.publisher : ""}</div>
            </div>
            <div className="piece-text-info">
              <div className="text-2">Grade Level</div>
              <div className="value">{piece ? piece.grade : ""}</div>
            </div>
            <div className="div-3">
              <div className="text-2">State List*</div>
              <div className="value">{""}</div>
            </div>
            <div className="div-3">
              <div className="text-2">Ranges</div>
              <div className="value">{""}</div>
            </div>
            <div className="div-3">
              <div className="text-2">Instrumentation</div>
              <div className="value">{""}</div>
            </div>
            <div className="div-3">
              <div className="text-3">
                Teaching <br />
                Concepts
              </div>
              <div className="value">{""}</div>
            </div>
            <div className="div-3">
              <div className="text-3">
                Composer <br />
                Demographics
              </div>
              <div className="value">{""}</div>
            </div>
            <div className="div-3">
              <div className="text-3">
                Cultural <br />
                Influence
              </div>
              <div className="value">{""}</div>
            </div>
            <div className="div-3">
              <div className="text-2">Type</div>
              <div className="value">{""}</div>
            </div>
            <div className="data-history">
              <div className="text-2">Data History</div>
              <p className="p">
                Last edited 11/6/2023
                <br />
                Approved by moderator
                <br />
                Added by a moderator
              </p>
            </div>
            <div className="edit-disclaim">
              <div className="edit-button">
                <div className="text-4" onClick={() => {
                  window.sessionStorage["pieceId"] = pieceId;
                  window.location.href = "/editpiece";
                  }}>Suggest an edit</div>
                </div>
              <p className="disclaimer">
                <span>*</span>
                <span className="disclaimer-italics">Score-Score</span>
                <span> can not guarantee state band list accuracy. Be sure to check official listings, and if you are aware of state list updates, please let us know.</span>
              </p>
            </div>
        </div>
      </div>
    );
  }

  return (  
          <div className="expand-box-extended">
              <div className="more-details" onClick={toggleStyle}>More Details</div>
              <img
                className="details-drop-down"
                alt="Drop down"
                src="/img/dropDownDown.svg"
                onClick={toggleStyle}
              />
          </div>
  );
}



export default PieceView;
        