import { Link } from "react-router-dom";
import React from "react";
import "./navBar.css";

export const NavBar = ({
  instanceName,

  band = "/img/band.svg",
  helpIcon = "/img/helpIcon.svg",
  logo = "/img/logo.svg",
  profileIcon = "/img/profileIcon.svg",  
  searchIcon = "/img/searchIcon.svg"
}) => {
  return (
<div className={`nav-bar ${instanceName}`}>        
                  <Link to="/">
                    <img className="score-score-logo" alt="Logo" src={logo} />
                  </Link>
                  <nav>
                  <ul>
                    <div className="band-logo-holder">
                      <img className="band-logo" alt="Band" src={band} />
                    </div>
                    <li>
                      <div className="nav-bar-button-holder">
                        <img className="nav-bar-right-button" alt="Help icon" src={helpIcon} />
                        <div className="nav-bar-button-text">Help</div>
                      </div>
                    </li>
                    <li>
                      <Link to="/searchpage">
                        <div className="nav-bar-button-holder">
                          <img className="nav-bar-right-button" alt="Search icon" src={searchIcon} />
                          <div className="nav-bar-button-text">Search</div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/loginpage">
                        <div className="nav-bar-button-holder">
                          <img className="nav-bar-right-button" alt="Profile icon" src={profileIcon} />
                          <div className="nav-bar-button-text">Profile</div>
                        </div>
                      </Link>
                    </li>
                  </ul>
                  </nav>
                </div>
  );
};


export default NavBar;