import React from "react";
import user from "../User/user";
import setUser from "../User/setUser";
import requestUrl from "../variables/requestUrl";
import request from "../Request/request";
import methods from "../Request/methods";
import contents from "../Request/contents";
import logout from "./logout";

function authUser(username, password) {
    logout();
    request("/login", methods.post, contents.auth, "username=" + username + "&password=" + password,
        function(data) {
            // Success. Store token.
            window.sessionStorage.setItem("token", data['accessToken']);
            window.sessionStorage.setItem('loggedIn', 'true');
            setUser(user.constructor(data['roles']));
            window.location = "/loginpage";
        },
        function() {
            alert("Error!");
        });
}

export default authUser;