import React from "react";
import requestUrl from "../variables/requestUrl";
import request from "../Request/request";
import methods from "../Request/methods";
import contents from "../Request/contents";

function deAuthUser() {
    request("/logout", methods.post, contents.auth, null,
        function(data) {
            window.sessionStorage.removeItem('token');
            window.sessionStorage.removeItem('loggedIn');
        });
}

export default deAuthUser;