import React from "react";
import user from "../User/user";
import setUser from "../User/setUser";
import requestUrl from "../variables/requestUrl";
import request from "../Request/request";
import methods from "../Request/methods";
import contents from "../Request/contents";

function createAccount(username, firstName, lastName, email, password, positions) {

    request("/api/contributor", methods.post, contents.json,
        JSON.stringify({name:username, firstName:firstName, lastName:lastName, email:email, password:password, teachingPositions:positions}),
        function() {
            window.location = "/loginpage";
            alert("Account created");
        },
        function () {
            alert("Failed to create account");
        },
        function () {
            alert("Congrats, this error should never happen");
        });
}

export default createAccount;