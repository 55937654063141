import React, {useEffect, useRef, useState} from "react";
import "./EditPiecePage.css";
import {Link} from "react-router-dom";
import NavBar from "../NavBar";
import request from "../Functions/Request/request";
import contents from "../Functions/Request/contents";
import methods from "../Functions/Request/methods";

function EditPieceView(props) {
    const [piece, setPiece] = useState(undefined);
    const [update, setUpdate] = useState(true);

    const pieceId = window.sessionStorage["pieceId"];
    useEffect(() => {
        request("/api/piece?piece_id=" + pieceId, methods.get, contents.json, null, (data) => {
            setPiece(data);
        });

    }, [update]);

    return (
    <div className="editPage">
      <div className="the-content">
      <NavBar instanceName="editPiecePageNavBar"/>
        <div className="info">
          <div className="main-data">
            <div className="top-info">
              <div className="main-info">
                <div className="pieceTitle">{piece ? piece.title : "Loading"}</div>
                <div className="pieceComposer">{piece ? piece.composer : ""}</div>
              </div>
            </div>
          </div>
          <div className="extra-info">
            <div className="piece-text-info">
              <div className="text-2">Arranger</div>
              <textarea className="value" value={piece ? piece.arranger : ""} onChange={(e) =>  {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
            </div>
            <div className="piece-text-info">
              <div className="text-2">Publisher</div>
              <textarea className="value" value={piece ? piece.publisher : ""} onChange={(e) =>  {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
            </div>
            <div className="piece-text-info">
              <div className="text-2">Grade Level</div>
              <textarea className="value" value={piece ? piece.grade : ""} onChange={(e) =>  {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
            </div>
            <div className="div-3">
              <div className="text-2">State List*</div>
              <textarea className="value" onChange={(e) =>  {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
            </div>
            <div className="div-3">
              <div className="text-2">Ranges</div>
              <textarea className="value" onChange={(e) =>  {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
            </div>
            <div className="div-3">
              <div className="text-2">Instrumentation</div>
              <textarea className="value" onChange={(e) =>  {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
            </div>
            <div className="div-3">
              <div className="text-3">
                Teaching <br />
                Concepts
              </div>
              <textarea className="value" onChange={(e) =>  {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
            </div>
            <div className="div-3">
              <div className="text-3">
                Composer <br />
                Demographics
              </div>
              <textarea className="value" onChange={(e) =>  {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
            </div>
            <div className="div-3">
              <div className="text-3">
                Cultural <br />
                Influence
              </div>
              <textarea className="value" onChange={(e) =>  {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
            </div>
            <div className="div-3">
              <div className="text-2">Type</div>
              <textarea className="value" onChange={(e) =>  {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
            </div>
            <div className="data-history">
              <div className="text-2">Data History</div>
              <p className="p">
                Last edited 11/6/2023
                <br />
                Approved by moderator
                <br />
                Added by a moderator
              </p>
            </div>
            <div className="edit-disclaim">
              <p className="disclaimer">
                <span>*</span>
                <span className="disclaimer-italics">Score-Score</span>
                <span> can not guarantee state band list accuracy. Be sure to check official listings, and if you are aware of state list updates, please let us know.</span>
                </p>
            </div>
          </div>
        </div>
      <div className="refs-and-ratings">
          <div className="references">
            <div className="ref-header-text">
              <div className="ref-header-text-2">References</div>
            </div>
            <div className="the-links">
              <p className="links">
                Wind Repertory Project Link
                <br />
                Director’s Notes Link
                <br />
                Audio Reference #1
                <br />
                Audio Reference #2

                <textarea className="value" onChange={(e) =>  {
                  e.target.style.height = "0px";
                  e.target.style.height = (e.target.scrollHeight + 5) + "px";
              }}/>
              </p>
            </div>
          </div>
        </div>
        <div className="edit-button-holder">
          <div className="edit-button" onClick={() => {
              window.sessionStorage["pieceId"] = pieceId;
              window.location.href = "/piecepage";
              }}>Cancel
          </div>
          <div className="edit-button"onClick={() => {
              window.sessionStorage["pieceId"] = pieceId;
              window.location.href = "/piecepage";
              }}>Submit
          </div>
      </div>
      <div className="extra-spacer"/>
      </div>
    </div>
    );
}




function StateList(props) {
  return (
      <div className="piecepage-list-item">
          <div className="listArea">
              <p className="piecepage-state-name">
                  {props.stateName}
              </p>
          </div>
      </div>
  );
}



export default EditPieceView;
        