import "./App.css";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import SearchPage from "./components/SearchPage";
import MainPage from "./components/MainPage";
import LoginPage from "./components/LoginPage";
import CreateAccountPage from "./components/CreateAccountPage";
import ProfilePage from "./components/ProfilePage";
import PiecePage from "./components/PiecePage";
import EditPiecePage from "./components/EditPiecePage";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/searchpage">
          <SearchPage {...searchPageData} />
        </Route>
        <Route path="/:path(|mainpageblack-white)">
          <MainPage {...MainPageData} />
        </Route>
        <Route path="/loginpage">
          <LoginPage
            username="Email"
            password="Password"
            rememberMe="Remember me"
            login2="Login"
            notAMemberSignUpNow="Not a member? Sign up now"
          />
        </Route>
          <Route path="/profile">
              <ProfilePage {...profilePageData}/>
          </Route>
          <Route path="/createaccount">
              <CreateAccountPage/>
          </Route>
          <Route path="/piecepage">
              <PiecePage
                  {...piecePage}
              />
          </Route><Route path="/editpiece">
              <EditPiecePage/>
          </Route>
      </Switch>
    </Router>
  );
}

export default App;
const searchPageData = {
    searchForPiece: "Search for a Piece"
};

const profilePageData = {
    profile: "Profile",
    spanText1: "Your Subscription Status",
    spanText3: "Score-Score: Enhanced Tier",
    recentActivities: "Recent Activities",
    spanText7: "You ",
    spanText8: "reviewed",
    spanText9: (
        <React.Fragment>
            {" "}
            Fugue in G minor by Bach
            <br />
        </React.Fragment>
    ),
    spanText10: "On 01/20/2023",
};

const MainPageData = {
    benefitText: "takes the stress out of programming for ensembles and makes it easy to find new, diverse, and engaging pieces.",
    createAcctText: "Create a free account!",
    filledNote: "/img/fullNote.svg",
    gradeText: "Grade: ",
    halfFillNote: "/img/halfNote.svg",
    headerText: "Program Your Next Favorite Piece",
    joinConversation: "Want to join the conversation?",
    numRatings: "150",
    pieceOfDay: "Piece of the Day - Check it Out!",
    scoreScore: "Score-Score",
    searchIcon: "/img/searchBlk.svg",
    searchText: "Start Your Next Program",
    tempComp: "Barber",
    tempGrade: "4",
    tempRating: "4.5",
    tempStars: "/img/stars.svg",
    tempTitle: "Intermezzo From Vanessa",
};

const piecePage = {
    dropDownDown: "/img/dropDownDown.svg"
};




