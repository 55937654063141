import React from "react";
import "./CreateAccountPage.css";
import createAccount from "../Functions/Authentication/createAccount";
import request from "../Functions/Request/request";
import methods from "../Functions/Request/methods";
import Contents from "../Functions/Request/contents";
import {useEffect} from "react";
import NavBar from "../NavBar";

function CreateAccountPage(props) {
  const { } = props;
  useEffect(() => {
    fillOptions();
  });
  return (
    <div className="container-center-horizontal">
      <NavBar instanceName="loginPageNavBar"/>
      <div className="CreateAccountPage screen">
        <div className="overlap-group7">
        </div>
        <div className="overlap-group10">
          <div className="login account-creation-details">Account Details</div>
          <div className="overlap-group2-1">
            <input id="usernameField" type="text" className="username account-creation-enter-text" placeholder="username"></input>
          </div>
          <div className="overlap-group2-1">
            <input id="firstNameField" type="text" className="username account-creation-enter-text" placeholder="first name"></input>
          </div>
          <div className="overlap-group2-1">
            <input id="lastNameField" type="text" className="username account-creation-enter-text" placeholder="last name"></input>
          </div>
          <div className="accountCreation-roleSelector-overlap">
          <div className="username rolefield-explain-text">Teaching Level(s)</div>
            <select id="roleField" className="username roleSelector account-creation-enter-text" multiple>
            </select>
          </div>
          <div className="overlap-group2-1">
            <input id="emailField" type="text" className="username account-creation-enter-text" placeholder="email"></input>
          </div>
          <div className="overlap-group1-1">
            <input id="passwordField" type="password" className="username account-creation-enter-text" placeholder="password"></input>
          </div>
          <div className="overlap-group1-1">
            <input id="passwordField2" type="password" className="username account-creation-enter-text" placeholder="confirm password"></input>
          </div>
          <div>
            <div className="create-account-button" onClick={createAccountClicked}>Create Account</div>
          </div>
        </div>
        <br></br><br></br>
      </div>
    </div>
  );
}
function createAccountClicked() {
  let password1 = document.getElementById("passwordField").value;
  let password2 = document.getElementById("passwordField2").value;
  let options = document.getElementById("roleField")
  let selected = []
  for (let i = 0; i < options.length; i++) {
    let item = options.options[i];
    if (item.selected) {
      selected.push({positionName:item.innerText, positionId:item.value})
    }
  }



  if (selected.length === 0) {
    alert("Please select the level of students you teach! ")
  }
  else if (password1 !== password2) {
    alert("Passwords do not match!");
  }
  else {
    createAccount(document.getElementById("usernameField").value,
        document.getElementById("firstNameField").value,
        document.getElementById("lastNameField").value,
        document.getElementById("emailField").value, password1, selected);
  }
}

let first = false;
function fillOptions(options) {
  // Prevent the available options from being added twice.
  if (!first) {
    request("/api/positions", methods.get, Contents.json, null,
        function(data) {
          let selector = document.getElementById("roleField");
          data.forEach(item => {
            let option = new Option(item.positionName, item.positionId);
            option.onmousedown = optionSelect;
            selector.options.add(option);
          })
        })
  }
}

function optionSelect(event) {
  event.preventDefault();
  this.selected = !this.selected;
}

export default CreateAccountPage;
