import React from "react";
import user from "../User/user";
import setUser from "../User/setUser";
import requestUrl from "../variables/requestUrl";

/**
 * A function to send requests to the backend API. Functions can be passed to handle
 * various responses from the server.
 * @param path The path to which to make the request.
 * @param method The method of the request (get, post, put, delete) (use methods variable from /Functions/Request/methods.jsx).
 * @param contents The contents of the request body.
 * @param body The contents of the request if any.
 * @param successAction The function to call on successful response (200). The data can be null if there is no json response. function(data)
 * @param failureAction The function to run on failed response (any except 200 and 401). function()
 * @param notLoggedInAction The function to run on not logged in response (401). function()
 * @param unAuthAction The function to run on unauthorized response (403). function()
 * @param customStatus A custom status code to check beyond the defaults.
 * @param customStatusAction A function to run when the status matches the status code. If the response contains data it will be passed to the function, otherwise null is passer.
 */
function request(path, method, contents, body, successAction = null, failureAction = null, notLoggedInAction = null,
                 unAuthAction = null, customStatus = null, customStatusAction = null) {
    let newRequest;
    if (body != null || body !== undefined) {
        if (window.sessionStorage.token !== undefined) {
            newRequest = {
                method: method,
                headers: {'Content-Type': contents,
                    'Authorization': window.sessionStorage.token},
                body: body
            };
        }
        else {
            newRequest = {
                method: method,
                headers: {'Content-Type': contents},
                body: body
            };
        }
    }
    else {
        if (window.sessionStorage.token !== undefined) {
            newRequest = {
                method: method,
                headers: {'Content-Type': contents,
                    'Authorization': window.sessionStorage.token},
            };
        }
        else {
            newRequest = {
                method: method,
                headers: {'Content-Type': contents},
            };
        }
    }

    fetch(requestUrl(path), newRequest).then((response) => {
        if (response.status === 403) {
            if (unAuthAction != null) {
                unAuthAction();
            }
        }
        else if (response.status === 200) {
            // Response is OK. Process the data
            response.json().then((data) => {
                if (successAction != null) {
                    successAction(data);
                }
            }).catch((error) => {
                if (successAction != null) {
                    successAction(null);
                }
            });
        }
        else if (response.status === 401) {
            if (notLoggedInAction != null) {
                notLoggedInAction();
            }
        }
        else if (response.status === customStatus) {
            if (customStatusAction != null) {
                response.json().then((data) => {
                    customStatusAction(data);
                }).catch((error) => {
                    customStatusAction(null);
                });

            }
        }
        else {
            if (failureAction != null) {
                failureAction();
            }
        }
    }).catch((error) => { alert("Failed to make request to API!")});
}

export default request;