import React from "react";

class user {
    roles;

    constructor(roles) {
        this.roles = roles;
    }

    isAdmin() {
        if (roles.contains('ROLE_admin')) {
            return true;
        }

        return false;
    }

    isContributor() {
        if (roles.contains('ROLE_contributor')) {
            return true;
        }

        return true;
    }
}

export default user;