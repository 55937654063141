import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./MainPage.css";
import NavBar from "../NavBar";

function MainPage(props) {
  const {
    benefitText,
    createAcctText,
    filledNote,
    gradeText,
    halfFillNote,
    headerText,
    joinConversation,
    numRatings,
    pieceOfDay,
    scoreScore,
    searchIcon,
    searchText,
    tempComp,
    tempGrade,
    tempRating,
    tempStars,
    tempTitle
  } = props;

  return (
<div className="main">
      <NavBar instanceName="mainNav"/>
        <div className="ellipse-image-holder"/>
          <div className="text-buttons">
            <div className="top-text">
              <p className="header">{headerText}</p>
              <p className="para">
                <span className="text-wrapper">{scoreScore}</span>
                <span className="span">
                  {" "}
                  {benefitText}
                </span>
              </p>
            </div>
            <Link to="/searchpage" style={{ textDecoration: 'none' }}>
              <div className="start-button">
                <div className="text-wrapper-2">{searchText}</div>
                <img
                  className="img-search-start"
                  alt="Search"
                  src={searchIcon}
                />
              </div>
            </Link>
            <div className="daily-piece">
              <p className="p">{pieceOfDay}</p>
              <Link to="/piecepage" style={{ textDecoration: 'none' }}>
                <div className="the-piece">
                  <div className="title-comp">
                    <p className="main-title">{tempTitle}</p>
                    <div className="main-composer">{tempComp}</div>
                  </div>
                  <div className="rating">
                    <img
                      className="stars"
                      alt="Stars"
                      src={tempStars}
                    />
                    <div className="text-wrapper-3">{tempRating}&nbsp;&nbsp;({numRatings})</div>
                  </div>
                  <div className="text-wrapper-3">{gradeText}{tempGrade}</div>
                </div>
                </Link>
            </div>
            <Link to="/createaccount" style={{ textDecoration: 'none' }}>
              <div className="join-button">
                <p className="join-text">
                  <span className="text-wrapper-4">
                    {joinConversation}
                    <br />
                  </span>
                  <span className="text-wrapper-5">{createAcctText}</span>
                </p>
              </div>
            </Link>
          </div>
        </div>
  );
};

export default MainPage;